export const category = ['All','News','Product','Industry','Education','Team','Project weekly'];
export const items = [
  {
    "title": "Roxe Project Update (June 7th to June 13th)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Roxe Payment Network from June 7th to June 13th, 2021.",
    "date": "2021-06-18",
    "url": "https://medium.com/roxechain/roxe-project-update-june-7th-to-june-13th-19146afc533e",
    "img": ""
  },
  {
    "title": "Using Blockchain To Improve Cross-Border Remittances",
    "category": "News",
    "desc": "But in an interview with PYMNTS, Josh Li, chief business officer of Roxe, said that businesses and individuals can take advantage of speeding up and streamlining cross-border remittances in a way that marries blockchain technology with traditional finance, while sidestepping the challenges of legacy settlement infrastructure and practices.",
    "date": "2021-06-21",
    "url": "https://www.pymnts.com/blockchain/2021/using-blockchain-to-improve-cross-border-remittances/",
    "img": "https://securecdn.pymnts.com/wp-content/uploads/2021/06/blockchain-cross-border-1000x600.jpg"
  },
  {
    "title": "El Salvador Approves Bitcoin as Legal Tender in “Supermajority”",
    "category": "News",
    "desc": "Bitcoin as legal tender is only the first step on the way toward central bank digital currencies (CBDCs), according to Roxe Inc. chief business officer Josh Li. “CBDCs will be a major catalyst for making crypto domestic and cross-border payments go mainstream. Why? With the right technology, CBDCs can not only make payments much faster and less expensive, but more interoperable between payment platforms and various financial institutions.” he said in an email to Blockworks on June 9.",
    "date": "2021-06-09",
    "url": "https://blockworks.co/el-salvador-approves-bitcoin-as-legal-tender-in-supermajority/",
    "img": "https://blockworks.co/wp-content/uploads/2021/06/1623219406278.jpeg"
  },
  {
    "title": "Roxe Global Payment Network Expands Further into India, Partners with IPAY to Launch Remittance Program",
    "category": "News",
    "desc": "Today we’re excited to announce that IPAY, a global online money transfer provider, will become a Roxe node to send remittances from the United States to beneficiaries in India.",
    "date": "2021-05-27",
    "url": "https://medium.com/roxepaymentnetwork/roxe-global-payment-network-expands-further-into-india-partners-with-ipay-to-launch-remittance-66835ca9df2",
    "img": "https://miro.medium.com/max/1280/1*yYUVviYvQDKEby9W6_8B3w.jpeg"
  },
  {
    "title": "ROC Will Go Live On International Tier 1 Exchange Bittrex (B-net)",
    "category": "News",
    "desc": "On May 24th at 23:00 (UTC+8), Roxe payment network ecosystem token, ROC, will be live on the global digital asset exchange Bittrex (also known as B-net) along with the ROC/USDT trading pair, with an open top-up time of May 21st at 23:00 (UTC+8).",
    "date": "2021-05-24",
    "url": "https://medium.com/roxechain/roc-will-go-live-on-international-tier-1-exchange-bittrex-b-net-62d64a22dfdb",
    "img": "https://miro.medium.com/max/2400/1*Ts0VB1ULyuuRaSHjpMNB7A.jpeg"
  },
  {
    "title": "Roxe Capital Limited Completes Series A Financing for Roxe Global Payment Network",
    "category": "News",
    "desc": "Roxe Capital Limited closed its Series A round of funding from investors including Chainup Capital, Bitrise Capital, Consensus Labs, Chain Capital, Chainfir Capital, Efund, K24 Ventures, N7 Labs, Timestars Capital, OPC, PAUVEY Capital, AKG Ventures, Pioneer Capital, Oscar Capital, and others.",
    "date": "2021-05-28",
    "url": "https://medium.com/roxepaymentnetwork/roxe-capital-limited-completes-series-a-financing-for-roxe-global-payment-network-d7fb43a1f5b0",
    "img": "https://miro.medium.com/max/4132/1*sJGm3egUc2UfVahJadUWAg.png"
  },
  {
    "title":"Roc Foundation Launches Supernodes Partner Program to Power a New Paradigm for Global Value Exchange",
    "category": "News",
    "desc": "Roxe Chain Infrastructure Powers Fast, Secure, and Cost-Effective Value Transfer Applications Anywhere in the World.",
    "date": "2020/9/10",
    "url": "https://www.businesswire.com/news/home/20200910005193/en/Roxe-Chain-Foundation-Launches-Supernodes-Partner-Program-to-Power-a-New-Paradigm-for-Global-Value-Exchange",
    "img": ""
  },
  {
    "title": "DO: A Universal Currency Bringing True Stability to Stablecoins",
    "category": "News",
    "desc": "Algorithmic stablecoins are one answer to this challenge, and carry high expectations for cryptocurrency innovation. Thus far, ambitious projects have faced an insurmountable chasm — a chasm that DO (pronounced “dough”) aims to bridge.",
    "date": "2021-05-14",
    "url": "https://medium.com/roxechain/do-a-universal-currency-bringing-true-stability-to-stablecoins-a6cec08ac53",
    "img": "https://miro.medium.com/max/2380/1*89FCG8hY-4I-OV61UwX3oA.jpeg"
  },
  {
    "title": "Why Roxe Beats SWIFT in the Race to Improve Global Payments",
    "category": "News",
    "desc": "Roxe is a next-generation, global payment network. Roxe uses blockchain technology to unify fragmented global payment systems so that payment and remittance companies, digital asset exchanges, stablecoin issuers, banks, central banks, and consumers can make the fastest, least expensive, and most reliable payments anywhere in the world.",
    "date": "2021-05-25",
    "url": "https://medium.com/roxepaymentnetwork/why-roxe-beats-swift-in-the-race-to-improve-global-payments-384a33eab9f3",
    "img": "https://miro.medium.com/max/2280/1*t8mujiYLZpPaVMLKP_-dCA.jpeg"
  },
  {
    "title": "What’s Roxe? The Next-Generation Global Payment Network on the Blockchain!",
    "category": "News",
    "desc": "Roxe has created a global payment network based on blockchain technology. It provides banks, central banks, payment companies, remittance providers, and consumers with the best peer-to-peer payment path, establishing real-time, low-cost cross-regional payment. ",
    "date": "2021-06-15",
    "url": "https://medium.com/roxechain/whats-roxe-the-next-generation-global-payment-network-on-the-blockchain-3882d4e850b5",
    "img": "https://miro.medium.com/max/399/0*XvefMcysUw7o-lpX"
  },
  {
    "title": "Roxe Continues Momentum in India, Partners with Fairexpay to Launch Remittance Program",
    "category": "News",
    "desc": "Today we’re continuing our momentum by announcing that Fairexpay, a leading global aggregator platform for cross-border payments, will become a Roxe payment node to provide remittance services to beneficiaries in India. ",
    "date": "2021-04-21",
    "url": "https://medium.com/roxepaymentnetwork/roxe-continues-momentum-in-india-partners-with-fairexpay-to-launch-remittance-program-fc63d3be410f",
    "img": "https://miro.medium.com/max/1620/1*fzRrjp0W7qZ5N-2gET674Q.jpeg"
  },
  {
    "title": "Roxe Expands Further into India, Partners with BuyUcoin to Launch Blockchain Pilot Program",
    "category": "News",
    "desc": "We’re excited to announce that BuyUcoin, a leading digital asset exchange platform, will begin a pilot program as a Roxe payment node for blockchain-based remittance products and services in India.",
    "date": "2021-04-07",
    "url": "https://medium.com/roxepaymentnetwork/roxe-expands-further-into-india-partners-with-buyucoin-to-launch-blockchain-pilot-program-bdb86f9c204e",
    "img": "https://miro.medium.com/max/950/1*XUNTl56JpUPPmDx4b08THA.jpeg"
  },
  {
    "title": "Roxe to Help Spark Global Economic Growth by Offering Free Central Bank Digital Currency Program",
    "category": "News",
    "desc": "Program to Provide Global CBDC Payment Network for Over 110 Qualifying Central Banks — For Free",
    "date": "2021-03-25",
    "url": "https://medium.com/roxepaymentnetwork/roxe-to-help-spark-global-economic-growth-by-offering-free-central-bank-digital-currency-program-1de1317242bb",
    "img": "https://miro.medium.com/max/5006/1*sHlLmj6CEQl3U0932qN8pQ.jpeg"
  },
  {
    "title": "Why is Roxe Better than Ripple XRPL for Global Payments?",
    "category": "News",
    "desc": "Today there are many problems in the global payments industry, such as slow, costly and unreliable cross-border remittances and payments for goods and services. A new global payment system based on blockchain technology is taking shape, and Roxe, a global payment network, is solving the aforementioned problems with a new paradigm for global payments.",
    "date": "2021-03-05",
    "url": "https://medium.com/roxepaymentnetwork/why-is-roxe-better-than-ripple-xrpl-for-global-payments-70630837b41d",
    "img": "https://miro.medium.com/max/5000/1*Rk4QY7Ugzjkc7h9ArN2eCQ.jpeg"
  },
  {
    "title": "Why Ripple’s XRP Fails as a Bridge Asset",
    "category": "News",
    "desc": "CBDCs are primarily being created to improve domestic currency management — more control over money supply, less reliance on cash, faster settlement between in-country parties, more automated processes, fewer physical processes, and more.",
    "date": "2021-03-04",
    "url": "https://medium.com/roxepaymentnetwork/why-ripples-xrp-fails-as-a-bridge-asset-1c1fa042d765",
    "img": "https://miro.medium.com/max/2560/1*8_q8zZhw3_rn4GmkvKXgaQ.jpeg"
  },
  {
    "title": "Roxe Launches World’s First Global, Central Bank Digital Currency Payment Network",
    "category": "News",
    "desc": "CBDC Payment Network Enables Central Banks to Issue, Manage, and Distribute CBDCs Across the World.",
    "date": "2021-03-02",
    "url": "https://medium.com/roxepaymentnetwork/roxe-launches-worlds-first-global-central-bank-digital-currency-payment-network-a8095b554d4d",
    "img": "https://miro.medium.com/max/2048/1*FD30qjZivpJ583bpZQ8X3w.jpeg"
  },
  {
    "title": "Roxe Announces A Global CBDC Payment Network Powered By Blockchain",
    "category": "News",
    "desc": "Central Bank Digital Currencies (CBDCs) are the flavor of the year and probably that of the decade.",
    "date": "2021-03-02",
    "url": "https://www.forbes.com/sites/vipinbharathan/2021/03/02/roxe-announces-a-global-cbdc-payment-network-powered-by-blockchain/?sh=69d42be72947",
    "img": "https://thumbor.forbes.com/thumbor/960x0/https%3A%2F%2Fspecials-images.forbesimg.com%2Fimageserve%2F603ea2e3a66838fb4396a8a2%2F960x0.jpg%3Ffit%3Dscale"
  },
  {
    "title": "How Roxe Helps Neobanks Make Faster, Cheaper International Payments",
    "category": "News",
    "desc": "Roxe Instant Settlement Network (RISN or simply “Roxe”) empowers neobanks and traditional financial institutions to offer their customers cross-border payments in seconds (instead of days) while cutting delivery expenses by over 50%. (RISN is also flexible enough to service banks, central banks, remittance and payment companies, and digital asset exchanges).",
    "date": "2021-02-18",
    "url": "https://medium.com/roxepaymentnetwork/how-roxe-helps-neobanks-make-faster-cheaper-international-payments-7a172369214b",
    "img": "https://miro.medium.com/max/3200/1*5ROqyB0DGwzjFva3NyiWiw.jpeg"
  },
  {
    "title": "Webinar: The Future of International Payments",
    "category": "News",
    "desc": "Recently we had a great webinar discussion about how to make cross-border payments faster, cheaper, and more reliable with our esteemed industry peers Fabiola Herrera, Deputy Manager, Systems and Innovation at the Central Bank of the Dominican Republic, Kunbi Oguneye, CEO of N2Xpress, a fast-growing global remittance company, Jacob Aruldhas, CEO of ECS Fin, a global bank infrastructure provider, and our moderator, Thomas Trepanier, Head of Business Development at Roxe, a global payment network.",
    "date": "2021-02-03",
    "url": "https://medium.com/roxepaymentnetwork/webinar-the-future-of-international-payments-fd3b0e039157",
    "img": "https://miro.medium.com/max/2000/1*uVjXSVK2EwIfCFbUaVT8nA.jpeg"
  },
  {
    "title": "The Roxe Chain Manifesto: A New Model for Global Payments",
    "category": "News",
    "desc": "It’s why we developed Roxe Chain, a hybrid blockchain purpose-built for global payments and other value transfer applications. Roxe Chain connects highly fragmented financial systems, assets, and markets to make global instant settlement and cross-border payments faster, more reliable, and less expensive.",
    "date": "2021-01-13",
    "url": "https://medium.com/roxechain/the-roxe-chain-manifesto-a-new-model-for-global-payments-65953dbdeb3c",
    "img": "https://miro.medium.com/max/1620/1*5RZN0kBQZTCESPcBdNTsoA.jpeg"
  },
  {
    "title": "US Treasury’s Decision to Allow Stablecoins, Blockchains for Bank Payments Validates Roxe, a Global Payment Network",
    "category": "News",
    "desc": "This past Monday, The Office of the Comptroller of the Currency (OCC) issued big news: banks may now use blockchains and stablecoins for payments. We’re super excited about the OCC’s interpretive letter because it not only could help make crypto payments mainstream, but it also validates Roxe, our next-generation global payment network.",
    "date": "2021-01-08",
    "url": "https://medium.com/roxepaymentnetwork/us-treasurys-decision-to-allow-stablecoins-blockchains-for-bank-payments-validates-roxe-a-9dc60bdbfd33",
    "img": "https://miro.medium.com/max/1000/1*LJKFMSh2Lak8qOLLBHxOqA.jpeg"
  },
  {
    "title": "Why Roxe is Better than Ripple for Global Payments",
    "category": "News",
    "desc": "Cross-border payments are too slow, too expensive and too complicated due to a highly fragmented global financial system. International payments and remittances are ripe for blockchain innovation. We’re working hard to solve these problems with Roxe, our open, global payment network, and to demonstrate how our approach is uniquely valuable.",
    "date": "2021-01-07",
    "url": "https://medium.com/roxepaymentnetwork/why-roxe-is-better-than-ripple-for-global-payments-3802ba9eeab8",
    "img": "https://miro.medium.com/max/2000/1*uDNfCzJSQGfSjJAsrKpYyg.jpeg"
  },
  {
    "title": "Roxe Ties with N2Xpress for Blockchain-Based Remittance Pilot",
    "category": "News",
    "desc": "The company is trying to tap the major remittance markets with its blockchain-based services.",
    "date": "2020-12-17",
    "url": "https://www.financemagnates.com/fintech/news/roxe-ties-with-n2xpress-for-blockchain-based-remittance-pilot/",
    "img": "https://www.financemagnates.com/wp-content/uploads/2017/07/blockchain-locks.jpg"
  },
  {
    "title": "Why Blockchain is the Best Option to Create a CBDC",
    "category": "News",
    "desc": "Some industry banking leaders have called into question the need for blockchain. However, the fact is blockchain can provide the transparency (both identities and pricing), speed, security, cost savings and global settlement capabilities that are critical to the success of Central Bank Digital Currencies (CBDCs). As the financial world races to an always-on, 24/7 model, banking customer expectations are demanding faster, better, and more cost-efficient international clearing and settlement.",
    "date": "2020-11-25",
    "url": "https://medium.com/roxepaymentnetwork/why-blockchain-is-the-best-option-to-create-a-cbdc-a5052b5d4ee2",
    "img": "https://miro.medium.com/max/7972/1*RexpvPxzlS8v7z_k0NFRyQ.jpeg"
  },
  {
    "title": "New Usage Scenarios for ROC",
    "category": "News",
    "desc": "Roxe has upgraded the application use cases of the ROC token in order to improve the compatibility of the Roxe network with traditional financial systems and to expand the use cases for ROC. ",
    "date": "2021-07-24",
    "url": "https://medium.com/roxechain/new-usage-scenarios-for-roc-4f5c02054659",
    "img": "https://miro.medium.com/max/1280/1*BWaN-zFuejxc34YHEMuvbw.jpeg"
  },
  {
    "title": "How Roxe Helps Payment Service Providers",
    "category": "News",
    "desc": "The Roxe Instant Settlement Network (RISN, or simply “Roxe”) empowers payment service providers and traditional financial institutions to offer their customers cross-border payments in seconds (instead of days) while cutting delivery expenses by over 50%. (RISN is also flexible enough to service banks, central banks, remittance and payment companies, and digital asset exchanges).",
    "date": "2021-07-02",
    "url": "https://medium.com/roxepaymentnetwork/how-roxe-helps-payment-service-providers-e695ef561acd",
    "img": ""
  },
  {
    "title": "Why China is Trying to Ban Crypto",
    "category": "News",
    "desc": "As you may have heard in recent weeks, China has been blocking crypto exchange platforms from doing business. While local provincial governments (e.g., Qinghai and Sichuan) are now moving to inspect the use of their electricity and close any sites which are using its hydropower to fuel bitcoin output.",
    "date": "2021-06-28",
    "url": "https://medium.com/roxepaymentnetwork/why-china-is-trying-to-ban-crypto-e375f6ba4931",
    "img": ""
  },
  {
    "title": "Roxe Payment Network Announces Multicurrency On and Off Ramp Solution for Digital Asset Exchanges\r\n",
    "category": "News",
    "desc": "Digital Asset Exchange Business and Individual Users Can Make Faster, Less Expensive Deposits and Withdrawals with Over 100 Fiat Currencies.",
    "date": "2021-06-17",
    "url": "https://medium.com/roxepaymentnetwork/roxe-payment-network-announces-multicurrency-on-and-off-ramp-solution-for-digital-asset-exchanges-8f0cb7ca2ccb",
    "img": ""
  },
  {
    "title": "Roxe Announces Roxe Payment Protocol, World’s First On-chain Forex Swap Protocol",
    "category": "News",
    "desc": "New Protocol Uses DeFi Liquidity Pools to Deliver the Best Wholesale Foreign Exchange Rates to Any Individual, Business, Payment Company or Bank.",
    "date": "2021-06-11",
    "url": "https://medium.com/roxepaymentnetwork/roxe-announces-roxe-payment-protocol-worlds-first-on-chain-forex-swap-protocol-8babe6471e86",
    "img": ""
  },
  {
    "title": "Roxe blockchain payment network expands to Brazil",
    "category": "News",
    "desc": "Roxe, a next-generation global payment network, today announced that Rana Express, an international money transfer company, will become a Roxe node to send remittances from the United States to beneficiaries in Brazil using the Rana Wallet (Android and iOS versions).",
    "date": "2021-08-06",
    "url": "https://www.finextra.com/pressarticle/88840/roxe-blockchain-payment-network-expands-to-brazil",
    "img": ""
  },
  {
    "title": "Roxe Project Update (July 26 to August 1)",
    "category": "Project weekly",
    "desc": "Following is the latest project update from Roxe Payment Network, from July 26th to August 1st, 2021.",
    "date": "2021-08-05",
    "url": "https://medium.com/roxechain/roxe-project-update-july-26-to-august-1-f6487ea898ab",
    "img": ""
  },
  {
    "title": "Roxe Project Update (July 19th to July 25th)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Roxe Payment Network from July 19th to July 25th 2021.",
    "date": "2021-07-29",
    "url": "https://medium.com/roxechain/roxe-project-update-july-19th-to-july-25th-38766d492207",
    "img": ""
  },
  {
    "title": "Roxe Project Update (July 12th to July 16th)",
    "category": "Project weekly",
    "desc": "Following is the latest project update from Roxe Payment Network from July 12th to July 16th, 2021.",
    "date": "2021-07-22",
    "url": "https://medium.com/roxechain/roxe-project-update-july-12th-to-july-16th-9fe49732c109",
    "img": ""
  },
  {
    "title": "Roxe Project Update (July 5th to July 11th)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Roxe Payment Network from July 5th to July 11th, 2021.",
    "date": "2021-07-15",
    "url": "https://medium.com/roxechain/roxe-project-update-july-5-to-july-11-b24f29f73050",
    "img": ""
  },
  {
    "title": "Roxe Project Update (June 28th to July 4th)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Roxe Payment Network from June 28th to July 4th, 2021.",
    "date": "2021-07-12",
    "url": "https://medium.com/roxechain/roxe-project-update-june-28-to-july-4-c4cfd6d7c1eb",
    "img": ""
  },
  {
    "title": "Roxe Project Update (June 21st to June 27th)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Roxe Payment Network from June 21st to June 27th, 2021.",
    "date": "2021-06-30",
    "url": "https://medium.com/roxechain/roxe-project-update-june-21st-to-june-27th-3f3580d1d9b0",
    "img": ""
  },
  {
    "title": "Roxe Weekly Report (August 23-August 29)",
    "category": "Project weekly",
    "desc": "Here is the Roxe Payment Network’s latest project update from August 23 to August 29, 2021.",
    "date": "2021-09-02",
    "url": "https://medium.com/roxechain/roxe-weekly-report-august-23-august-29-64d58046bb84",
    "img": ""
  },
  {
    "title": "Roxe Project Update (August 16 to August 22)",
    "category": "Project weekly",
    "desc": "Here is the Roxe Payment Network’s latest project update from August 16 to August 22, 2021.",
    "date": "2021-08-26",
    "url": "https://medium.com/roxechain/roxe-project-update-august-16-to-august-22-b148b2dfbfa0",
    "img": ""
  },
  {
    "title": "Roxe Project Update (August 9 to August 14)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Roxe Payment Network from August 9 to August 14, 2021.",
    "date": "2021-08-20",
    "url": "https://medium.com/roxechain/roxe-project-update-august-9-to-august-14-3e74d52320aa",
    "img": ""
  },
  {
    "title": "Roxe Project Update (August 2 to August 8)",
    "category": "Project weekly",
    "desc": "Following is the latest project update from Roxe Payment Network from August 2nd to August 8th, 2021.",
    "date": "2021-08-13",
    "url": "https://medium.com/roxechain/roxe-project-update-august-2-to-august-8-d6b6d6fc45f5",
    "img": ""
  },
  {
    "title": "Interoperability: Roxe’s Key Feature that ‘Makes Money Smarter’ for Payment Service Providers",
    "category": "News",
    "desc": "At Roxe, we’re helping PSPs to make money even smarter by routing cross-border payments in seconds (instead of days) while cutting delivery costs in half. The key? An interoperable approach to using blockchain technology. ",
    "date": "2021-08-21",
    "url": "https://medium.com/roxepaymentnetwork/interoperability-roxes-key-feature-that-makes-money-smarter-for-payment-service-providers-fb96ca3e330c",
    "img": ""
  },
  {
    "title": "Roxe collaborates with Treviso to launch remittance program",
    "category": "News",
    "desc": "Roxe, a next-generation global payment network, today announced that Treviso, an international foreign exchange broker, will become a Roxe node to send remittances from the United States to beneficiaries in Brazil. The Roxe network is designed to save financial institutions significant time and costs by using blockchain technology to provide fast, inexpensive, and highly reliable clearing and cross-border settlement of payments and remittances.",
    "date": "2021-08-19",
    "url": "https://www.finextra.com/pressarticle/88970/roxe-collaborates-with-treviso-to-launch-remittance-program",
    "img": "IMG1"
  },
  {
    "title": "Roxe Is Building a Better, Interoperable Paradigm for Global Payments with The Best Talent in the World",
    "category": "News",
    "desc": "We’re building something special here at Roxe: a better, faster, more reliable way to make payments and send remittances with an interoperable approach to using blockchain technology. We’re also building something equally important to scale our growth and make our vision a reality: our team.",
    "date": "2021-08-14",
    "url": "https://medium.com/roxepaymentnetwork/roxe-is-building-a-better-interoperable-paradigm-for-global-payments-with-the-best-talent-in-the-529e8b9851d2",
    "img": ""
  },
  {
    "title": "Roxe hires top talent from Venmo, MoneyGram, Bakkt and Uphold",
    "category": "News",
    "desc": "Roxe, a next-generation global payment network, today announced that Esther Sun Jang, Yamini Sagar, Ahmed Elhenawy and Shujah Awan have joined the company to help lead the growth of the company’s global payment network for individuals, merchants, payment service providers, remittance companies, banks and central banks.",
    "date": "2021-08-12",
    "url": "https://www.finextra.com/pressarticle/88891/roxe-hires-top-talent-from-venmo-moneygram-bakkt--and-uphold/wholesale",
    "img": ""
  },
  {
    "title": "Roxe hires ex-IMF economist to lead Bitcoin hashrate-backed CBDC project",
    "category": "News",
    "desc": "Central bank digital currencies are the next frontier for blockchain. Roxe wants to make BTC hashrate the anchor for future CBDCs.",
    "date": "2021-09-08",
    "url": "https://cointelegraph.com/news/roxe-hires-ex-imf-economist-to-lead-bitcoin-backed-cbdc-project",
    "img": ""
  },
  {
    "title": "Roxe Project Update (August 30 to September 5)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Roxe Payment Network from August 30th to September 5th , 2021.",
    "date": "2021-09-10",
    "url": "https://medium.com/roxechain/roxe-project-update-august-30-to-september-5-d31623131616",
    "img": ""
  },
  {
    "title": "Roxe Project Update (September 6 to September 12)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Roxe Payment Network from September 6 to September 12, 2021.",
    "date": "2021-09-15",
    "url": "https://medium.com/roxechain/roxe-project-update-september-6-to-september-12-1d2460eb9a60",
    "img": ""
  },
  {
    "title": "SoftBank SG, Consensus Lab and other institutions have invested in Roxe Capital Limited to Mark their Entry into the Payment Services Market",
    "category": "News",
    "desc": "Roxe Capital Limited has announced that it completed its Series A funding round in May this year, with SoftBank SG (SoftBank Singapore), Consensus Lab, Chainup Capital, Bitrise Capital, Chain Capital, Chainfir Capital, K24 Ventures, OPC, AKG Ventures, and others.",
    "date": "2021-09-15",
    "url": "https://medium.com/roxepaymentnetwork/softbank-sg-consensus-lab-and-other-institutions-have-invested-in-roxe-capital-limited-to-mark-c922642525f4",
    "img": ""
  },{
    "title": "‘El Salvador’ became the first country to accept Bitcoin — Is it a smart move?",
    "category": "News",
    "desc": "By using the core principles of cryptography for verification and security, Roxe empowers payment apps to offer more features, increase profitability, and create a superior and modern experience for users.",
    "date": "2021-09-15",
    "url": "https://medium.com/roxechain/el-salvador-became-the-first-country-to-accept-bitcoin-is-it-a-smart-move-ec00532fc311",
    "img": ""
  },
  {
    "title": "Roxe Project Update (September 13 to September 19)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Roxe Payment Network from September 13 to September 19, 2021.",
    "date": "2021-09-23",
    "url": "https://medium.com/roxechain/roxe-project-update-september-13-to-september-19-eb1af3fca416",
    "img": ""
  },
  {
    "title": "Roxe Project Update (September 20 — September 26)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Roxe Payment Network from September 20 to September 26, 2021.",
    "date": "2021-09-30",
    "url": "https://medium.com/roxechain/roxe-project-update-september-20-september-26-dd549d097924",
    "img": ""
  },
  {
    "title": "Roxe launches ROC cross-chain bridge to layout BSC ecology on Oct 8",
    "category": "News",
    "desc": "Roxe Global Payment Network (referred to as Roxe) announced that it will open ROC’s “Ethereum⇌BSC Cross-Chain Bridge” contract address at 17:00 (UTC+8) on October 8.",
    "date": "2021-10-02",
    "url": "https://medium.com/roxechain/roxe-launches-roc-cross-chain-bridge-to-layout-bsc-ecology-on-oct-8-a3e6b6ac4549",
    "img": "https://miro.medium.com/max/1400/1*04B8tdwWgIg7KVFOAgbJ5Q.png"
  },
  {
    "title": "ROC will launch PancakeSwap on October 8th",
    "category": "News",
    "desc": "At 15:00 on October 8th (UTC+8), the Roxe ecological token ROC will be listed on PancakeSwap, and the ROC-BUSD trading pair will be opened simultaneously.",
    "date": "2021-10-07",
    "url": "https://medium.com/roxechain/roc-will-launch-pancakeswap-on-october-8th-20cc0c730ae0",
    "img": "https://miro.medium.com/max/1400/1*yuvx-YFhMSqpbowVZTSUsQ.gif"
  },
  {
    "title": "Roxe Project Update (September 27 — October 10)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Roxe Payment Network from September 27 to October 1o, 2021.",
    "date": "2021-10-14",
    "url": "https://medium.com/roxechain/roxe-project-update-september-27-october-10-5a14cec65817",
    "img": ""
  },
  {
  "title":"Roxe Project Update (October 11 — October 17)",
  "category": "Project weekly",
  "desc": "Here is the latest project update from Roxe Payment Network from October 11-17, 2021. ",
  "date": "2021-10-23",
  "url": "https://medium.com/roxechain/roxe-project-update-october-11-october-17-269b23a4c985",
  "img": ""
  },
  {
    "title":"Roxe Project Update (October 18 — October 24)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Roxe Payment Network from October 18–24, 2021. ",
    "date": "2021-10-29",
    "url": "https://medium.com/roxechain/roxe-project-update-october-18-october-24-585c70fd2d4b",
    "img": ""
  },
  {
    "title":"Roxe Project Update (October 25 — October 31)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Roxe Payment Network from October 25–31, 2021.",
    "date": "2021-11-03",
    "url": "https://medium.com/roxechain/roxe-project-update-october-25-october-31-58f64f8977b3",
    "img": ""
  },
  {
    "title":"Roxe Project Update (November 1 — November 7)",
    "category": "Project weekly",
    "desc": "Here are the latest project updates from Roxe Payment Network from November 1 — 7, 2021.",
    "date": "2021-11-07",
    "url": "https://medium.com/roxechain/roxe-project-update-november-1-november-7-93e0bedfa05a",
    "img": ""
  },
  {
    "title":"Roxe Project Update (November 8— November 14)",
    "category": "Project weekly",
    "desc": "Here are the latest project updates from Roxe Payment Network from November 8–14, 2021.",
    "date": "2021-11-14",
    "url": "https://medium.com/roxechain/roxe-project-update-november-8-november-14-5daba19b167e",
    "img": ""
  },
  {
    "title":"Roxe Project Update (November 15— November 21)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Roxe Payment Network from Nov 15 to Nov 21, 2021.",
    "date": "2021-11-21",
    "url": "https://medium.com/roxechain/roxe-project-update-november-15-november-21-955784fdf0e5",
    "img": ""
  },
  {
    "title":"Roxe Project Update (November 22 — November 28)",
    "category": "Project weekly",
    "desc": "Roxe is developing the next generation payment network. Here, we are sharing all the project updates of last week.",
    "date": "2021-11-28",
    "url": "https://medium.com/roxechain/roxe-project-update-november-22-november-28-2130eb19351",
    "img": ""
  },
  {
    "title":"Roxe Project Update (November 29 — December 05)",
    "category": "Project weekly",
    "desc": "Read the latest development updates from Roxe Payment Network! Be a part of the next-generation open global payment network by downloading our smartphone app. Here is the latest project update from Nov 29 to Dec 5, 2021.",
    "date": "2021-12-05",
    "url": "https://medium.com/roxechain/roxe-project-update-november-29-december-05-9b4889081163",
    "img": ""
  },
  {
    "title": "Roxe Project Update (December 06 — December 12)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Dec 6 to Dec 12, 2021.",
    "date": "2021-12-12",
    "url": "https://medium.com/roxechain/roxe-project-update-december-06-december-12-fa82c2fa1c9a",
    "img": ""
  },
  {
    "title": "Roxe Project Update (December 13 — December 19)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Dec 13 to Dec 19, 2021.",
    "date": "2021-12-19",
    "url": "https://medium.com/roxechain/roxe-project-update-december-13-december-19-a80a04551b42",
    "img": ""
  },
  {
    "title": "Roxe Project Update (December 20 — December 26)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Dec 20 to Dec 26, 2021.",
    "date": "2021-12-26",
    "url": "https://medium.com/roxechain/roxe-project-update-december-20-december-26-72951e2621a3",
    "img": ""
  },
  {
    "title": "Roxe Project Update (December 27 — January 02)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Dec 27, 2021 to Jan 02, 2022.",
    "date": "2022-01-02",
    "url": "https://medium.com/roxechain/roxe-project-update-december-27-january-02-89c89f8283ee",
    "img": ""
  },
  {
    "title": "Roxe Project Update (January 03 — January 09)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Jan 03 to Jan 09, 2022.",
    "date": "2022-01-09",
    "url": "https://medium.com/roxechain/roxe-project-update-january-03-january-09-49d6f588fc1e",
    "img": ""
  },
  {
    "title": "Roxe Project Update (January 10 — January 16)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Jan 10 to Jan 16, 2022.",
    "date": "2022-01-16",
    "url": "https://medium.com/roxechain/roxe-weekly-update-2-22-new-ios-android-app-introductory-contents-influencers-b7dca6655bc",
    "img": ""
  },
  {
    "title": "Roxe Project Update (January 17 — January 23)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Jan 17 to Jan 23, 2022.",
    "date": "2022-01-23",
    "url": "https://medium.com/roxechain/roxe-weekly-update-3-2022-deal-a-day-merchant-portal-design-rostock-sdk-api-risn-new-1a7d954fa78e",
    "img": ""
  },
  {
    "title": "Roxe Project Update (January 24 — January 30)",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Jan 24 to Jan 30, 2022.",
    "date": "2022-01-30",
    "url": "https://medium.com/roxechain/roxe-weekly-update-4-2022-push-notification-deal-a-day-features-private-chain-deployment-30833ec7f312",
    "img": ""
  },
  {
    "title": "Roxe Project Update (January 31 — February 06）",
    "category": "Project weekly",
    "desc": "Here is the latest project update from Jan 31 to Feb 06, 2022.",
    "date": "2022-02-06",
    "url": "https://medium.com/roxechain/roxe-weekly-update-5-2022-new-version-release-date-and-others-660012a204dc",
    "img": ""
  }
]