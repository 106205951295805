<template>
  <div @click="open(data)" class="item">
    <div class="img">
      <img :src="getimg(data)" />
    </div>
    <div class="cc">{{data.category}}</div>
    <div class="t1">{{data.title}}</div>
    <div class="t2">{{data.desc}}</div>
    <div class="date">{{data.date}}</div>
  </div>
</template>
<script>
import blog_en from '@/assets/img/blog_en.png'
import blog_zh from '@/assets/img/blog_zh.png'
import project from '@/assets/img/prog.png'
import news from '@/assets/img/news.png'
import IMG1 from '@/assets/img/WechatIMG322.png'
export default {
  props:["data"],
  methods: {
    getimg(item) {
      if(item.img == 'IMG1') {
        return IMG1
      }
      if(item.img) {
        return item.img;
      } else {
        if(item.category == '项目周报' || item.category == 'Project weekly') {
          return project;
        }
         if(item.category == '新闻' || item.category == 'News') {
          return news;
        }
        if(this.$i18n.locale == 'zh-cn') {
          return blog_zh
        } else {
          return blog_en;
        }
      }
    },
    open() {
      if(this.data.url) {
        this.$i18n.locale
        window.open(this.data.url,'_blank');
      }
    }
  }
}
</script>
<style lang="less" scoped>
.item{
  border-radius: 14px;
  overflow: hidden;
  cursor: pointer;
  margin: 20px;
  background: #fff;
  padding-bottom: 24px;
  .date{
    color: #828282;
    font-family: Louis George Cafe;
    font-size: 14px;
    margin-top: 20px;
    margin-left: 20px;
  }
  .cc{
    box-sizing: border-box;
    display: inline-block;
    padding: 2px 6px;
    background: #F4FBFF;
    margin-left: 20px;
    margin-bottom: 20px;
    border-radius: 2px;
    color: #80CEFF;
    font-size: 12px;
    font-family: Louis George Cafe;
  }
  .img {
    position: relative;
    padding-top: 60%;
    width: 100%;
    margin-bottom: 22px;
    display: flex;
    justify-content: center;
    img{
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
  .t1 {
    font-size: 24px;
    color: #085DA3;
    font-family: ParmaPetit;
    padding: 0 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .t2 {
    padding: 0 20px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 42px;
    overflow: hidden;
    color: #818E9E;
    font-family: Louis George Cafe;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
      margin-right: 7px;
    }
    .info{
      font-family: Louis George Cafe;
      font-size: 14px;
      
    }
  }
}
</style>