export const category = ['全部', '新闻', '产品', '行业', '教育', '团队', '项目周报'];
export const items = [
  {
    "title": "Roxe项目进展（0614—06.20）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-06-21",
    "url": "https://mp.weixin.qq.com/s/9P-D45e3j8vo2qEic3xBMQ",
    "img": ""
  },
  {
    "title": "Roxe项目进展（06.07—06.13）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-06-15",
    "url": "https://mp.weixin.qq.com/s/x8yVaC4RgpchZOVRA3qy-w\n",
    "img": ""
  },
  {
    "title": "Roxe项目进展（05.31—06.04）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-06-07",
    "url": "https://mp.weixin.qq.com/s/qTr4Z9wzMBDwIAaMtnB82w\n",
    "img": ""
  },
  {
    "title": "SWIFT 真的无可替代吗？浅析 Ripple 与 Roxe 等区块链的机会",
    "category": "新闻",
    "desc": "传统清结算系统需要周期短、效率高的更优解，以 Ripple 和 Roxe 为代表的区块链技术能为金融机构提供即时结算服务。",
    "date": "2020/8/17",
    "url": "https://www.chainnews.com/articles/586658529288.htm",
    "img": "https://img.chainnews.com/material/images/c0eb6a4f3dd8544b90c61639937dab1d_JWYprEB.png-article"
  },
  {
    "title": "Roxe项目进展（05.24—05.30）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-05-31",
    "url": "https://mp.weixin.qq.com/s/YJxoxnGylKndiVxxr5S6QA\n",
    "img": ""
  },
  {
    "title": "Roxe项目进展（05.17—05.23）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-05-24",
    "url": "https://mp.weixin.qq.com/s/LB9ZNqzg9-jsvL4DCiawAA\n",
    "img": ""
  },
  {
    "title": "Roxe项目进展（05.10—05.16）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-05-17",
    "url": "https://mp.weixin.qq.com/s/GtfCcb2yJlb2jbnHr7nGgg\n",
    "img": ""
  },
  {
    "title": "Roxe项目进展（04.26—05.09）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-05-10",
    "url": "https://mp.weixin.qq.com/s/KE8q4FH2BycdytJG6iQTnw",
    "img": ""
  },
  {
    "title": "Roxe项目进展（04.19—04.25）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-04-26",
    "url": "https://mp.weixin.qq.com/s/e0lIvZEnFMdkSP43W5UcJA\n",
    "img": ""
  },
  {
    "title": "Roxe项目进展（04.12—04.18）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-04-19",
    "url": "https://mp.weixin.qq.com/s/lYjNXRY1DmJn44FlDjcmRA\n",
    "img": ""
  },
  {
    "title": "Roxe项目进展（04.05—04.11）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-04-12",
    "url": "https://mp.weixin.qq.com/s/FRy5uEmlmYrI2EzbW7ZDVQ\n",
    "img": ""
  },
  {
    "title": "Roxe项目进展（03.29—04.04）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-04-06",
    "url": "https://mp.weixin.qq.com/s/HACrFwI6GO-SPVNt46O2MA",
    "img": ""
  },
  {
    "title": "Roxe项目进展（03.22—03.28）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-03-29",
    "url": "https://mp.weixin.qq.com/s/XULzENVc4sfQPrj9iNP1eg",
    "img": ""
  },
  {
    "title": "Roxe项目进展（03.15—03.21）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-03-22",
    "url": "https://mp.weixin.qq.com/s/5ALVrdvNn8z4rtrvdhPrqw",
    "img": ""
  },
  {
    "title": "Roxe项目进展（03.08—03.14）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-03-15",
    "url": "https://mp.weixin.qq.com/s/OY7C6sukjMxKkTEeh0BlQw",
    "img": ""
  },
  {
    "title": "Roxe项目进展（03.01—03.07）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-03-08",
    "url": "https://mp.weixin.qq.com/s/MDJLGJM8z_spM5Vv0MH-Vw",
    "img": ""
  },
  {
    "title": "Roxe项目进展（02.22—02.28）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-03-01",
    "url": "https://mp.weixin.qq.com/s/r88oxmdl1CN_Uiadm3SsnQ",
    "img": ""
  },
  {
    "title": "Roxe项目进展（01.25—01.31）",
    "category": "项目周报",
    "desc": "Roxe是下一代全球支付网络。",
    "date": "2021-02-01",
    "url": "https://mp.weixin.qq.com/s/zSw5MSFMJYhMdBvW6EQ6Lg",
    "img": ""
  },
  {
    "title": "Roxe Inc. 首席商务官 Josh Li：BTC 作为法定货币只是迈向 CBDC 的第一步",
    "category": "新闻",
    "desc": "萨尔瓦多总统 Nayib Bukele 在推特上表示，6 月 9 日萨尔瓦多国会以“绝对多数”的赞成票通过了《比特币法》，使该国成为全球第一个正式采用比特币作为法定货币的国家。",
    "date": "2021-06-22",
    "url": "https://www.chainnews.com/articles/307001236013.htm",
    "img": "https://img.chainnews.com/material/images/fe4e72ae1dfe3d1866ee3f35fe06c12a.jpg-article"
  },
  {
    "title": "Roxe 参加迈阿密 Bitcoin2021 峰会",
    "category": "新闻",
    "desc": "目前，Roxe 正与萨尔瓦多洽谈为其提供免费的 CBDC 解决方案，以帮助该国实现数字货币战略。",
    "date": "2021-06-11",
    "url": "https://www.chainnews.com/articles/417885009235.htm",
    "img": "https://img.chainnews.com/material/images/a6ef5191ec9cbf10d296f37390fdd80a.jpg-article"
  },
  {
    "title": "Roxe 上线加密货币数据平台 LunarCRUSH",
    "category": "新闻",
    "desc": "据 LunarCRUSH 官网显示，已上线 Roxe （生态代币 ROC）项目。用户可在 LunarCRUSH 平台上查看 ROC 各项社交指标，包括市场价格、交易数量、社交媒体数据、参与度和定量情感分析等。",
    "date": "2021-06-04",
    "url": "https://www.chainnews.com/articles/337849933178.htm",
    "img": "https://img.chainnews.com/material/images/ce1d95431891551c1959052652f5c1b8.jpg-article"
  },
  {
    "title": "Ripple 前高管 Henrique P. Teixeira 加入 Roxe 支付网络",
    "category": "新闻",
    "desc": "近日，全球支付网络 Roxe 宣布，已任命 Ripple 前高管 Henrique P. Teixeira 担任 Roxe 商务总监。",
    "date": "2021-06-04",
    "url": "https://www.chainnews.com/articles/294316328043.htm",
    "img": "https://img.chainnews.com/material/images/ba7f8ec34e67ab9db2e814236544fd0d.jpg-article"
  },
  {
    "title": "Roxe 全球支付网络进一步拓展印度市场，与 IPAY 合作推出汇款计划",
    "category": "新闻",
    "desc": "5 月 27 日，下一代全球支付网络 Roxe 宣布，全球在线汇款供应商 IPAY 将成为 Roxe 节点，开展从美国到印度的汇款业务",
    "date": "2021-06-01",
    "url": "https://www.chainnews.com/articles/117392784486.htm",
    "img": "https://img.chainnews.com/material/images/43e56343ac599ab171a098c93e998426.jpg-article"
  },
  {
    "title": "ROC 强势登陆国际一线交易所 Bittrex （B 网）",
    "category": "新闻",
    "desc": "5 月 24 日 23:00 （UTC+8），Roxe 支付网络生态代币 ROC 将上线全球数字资产交易所 Bittrex （又称 B 网），并同步开放 ROC/USDT 交易对，开放充值时间为 5 月 21 日 23:00 （UTC+8）。",
    "date": "2021-05-21",
    "url": "https://www.chainnews.com/articles/245309948348.htm",
    "img": "https://img.chainnews.com/material/images/79a6058919527d3209d16e7cab04ee0c.jpg-article"
  },
  {
    "title": "支付网络 Roxe Capital Limited 完成 A 轮融资",
    "category": "新闻",
    "desc": "链闻消息，Roxe Capital Limited 完成 A 轮融资，投资机构包括 Chainup Capital、比升资本、共识实验室、Chain Capital、链杉资本、Efund、K24 Ventures、N7 Labs、Timestars Capital、OPC、PAUVEY Capital、AKG Ventures、先路资本、Oscar Capital 等。",
    "date": "2021-05-21",
    "url": "https://www.chainnews.com/news/658485041419.htm",
    "img": ""
  },
  {
    "title": "Roxe 已完成 99%ROC 销毁的公告",
    "category": "新闻",
    "desc": "5 月 18 日，Roxe 已完成 99%ROC 的销毁，销毁后 ROC 总流通量为 1 亿枚。目前，ROC 在以太坊上的流通量为 2500 万枚。",
    "date": "2021-05-18",
    "url": "https://www.chainnews.com/articles/391357111601.htm",
    "img": "https://img.chainnews.com/material/images/34bd3873f97fe750ee2d2d9b2c9a2b06.jpg-article"
  },
  {
    "title": "Roxe 如何在全球支付的竞赛中战胜 SWIFT？",
    "category": "新闻",
    "desc": "Roxe 是下一代的全球支付网络，其通过区块链技术来统一分散的全球支付系统，使支付和汇款公司、数字资产交易所、稳定币发行商、银行和消费者能够在世界任何地方进行最快、最便宜和最可靠的支付。",
    "date": "2021-06-16",
    "url": "https://www.chainnews.com/articles/957926714773.htm",
    "img": "https://img.chainnews.com/material/images/e2b39e88b76fbe7db63e984ec9233db4.jpg-article"
  },
  {
    "title": "Cointelegraph 中文 HUB | Roxe：全球支付网络的倡导者和领先者暨团队亚太见面会",
    "category": "新闻",
    "desc": "5月11日，Cointelegraph中文举办线上访谈对话栏目HUB ，本期主题为《Roxe：全球支付网络的倡导者和领先者暨团队亚太见面会》，邀请到了Roxe创始人兼CEO Haohan Xu、Roxe首席商务官Josh Li、Roxe技术副总裁Jesse Lu、Roxe商务总监Henrique P. Teixeira、Roxe业务发展总监Thomas J. Trépanier、Roxe产品总监Wade Tang和RISN（Roxe轻结算网络）产品总监Han Yang作为本期活动嘉宾。",
    "date": "2021-05-13",
    "url": "https://cointelegraphcn.com/news/cointelegraph-china-hub-roxe",
    "img": "https://imagescn.cointelegraphcn.com/images/717_aHR0cHM6Ly9jdGMtZmlsZS5vc3MtY24tc2hhbmdoYWkuYWxpeXVuY3MuY29tLzE0YjdjMWQ4OTVmZGQ1NzhkMGQyMDFkNGY1ZDA2Yjg0LnBuZw==.jpg"
  },
  {
    "title": "一文读懂 Roxe：即时、高效的下一代全球支付网络",
    "category": "新闻",
    "desc": "Roxe Payment Network 是一个基于区块链技术的全球支付网络， 为银行、中央银行、支付公司、汇款提供商和消费者等提供点对点的最优路径支付，可以实现实时、低成本的跨区域、跨货币、跨系统的支付。Roxe 提供了从共识层到应用层的完善的基础设施和丰富的生态。Roxe 生态系统由应用层、网络层、流动性层、共识层构成，目前共有 Roxe Pay、Roxe Commerce、Roxe 轻结算网络（RISN）三款拳头产品。\n\n",
    "date": "2021-05-18",
    "url": "https://www.chainnews.com/articles/660005846289.htm",
    "img": "https://img.chainnews.com/material/images/9fae24d93db21fbada4c0a6784cb7504.jpg-article"
  },
  {
    "title": "Roxe 权威解读 | 全球放水背景下，各国央行为何纷纷发行 CBDC？",
    "category": "新闻",
    "desc": "Roxe 支付网络于 4 月 21 日，邀请了火链科技研究院执行院长李慧、美国力研咨询公司创始人谷燕西、AscendEX （原 BitMax）联合创始人 &CEO; 曹晶，围绕 CBDC 的发展趋势进行了深入探讨，Roxe 支付网络 CEO Haohan 担任会议主持人。",
    "date": "2021-04-28",
    "url": "https://www.chainnews.com/articles/320198033579.htm",
    "img": "https://img.chainnews.com/material/images/5f44050a4c364b03e536e6499f68b7e4.jpg-article"
  },
  {
    "title": "Fairexpay 成为 Roxe 支付节点 为印度用户提供汇款服务",
    "category": "新闻",
    "desc": "4 月 21 日，下一代全球支付网络 Roxe 宣布，全球领先的跨境支付聚合平台 Fairexpay 将成为 Roxe 的支付节点，为印度用户提供汇款服务。",
    "date": "2021-04-25",
    "url": "https://www.chainnews.com/articles/964396833044.htm",
    "img": ""
  },
  {
    "title": "ROC 上线 Bilaxy 并开通 ROC-USDC 交易对",
    "category": "新闻",
    "desc": "4 月 14 日，Roxe 支付网络生态代币 ROC 上线 Bilaxy，并开通 ROC-USDC 交易对 (https://bilaxy.com/trade/ROC_USDC)。",
    "date": "2021-04-14",
    "url": "https://www.chainnews.com/articles/153918321507.htm",
    "img": "https://img.chainnews.com/material/images/66065380e44406ed4b8bd9d7c784e10d.jpg-article"
  },
  {
    "title": "BuyUcoin 成为 Roxe 支付节点 在印度进行试点计划",
    "category": "新闻",
    "desc": "4 月 8 日，下一代全球支付网络 Roxe 宣布已与领先的数字资产交易平台 BuyUcoin 达成战略合作，BuyUcoin 已成为 Roxe 的支付节点，并将在印度启动试点计划，试行基于区块链的汇款产品和服务。",
    "date": "2021-04-09",
    "url": "https://www.chainnews.com/articles/170370625587.htm",
    "img": ""
  },
  {
    "title": "DO：依托全球支付体系的世界货币野望",
    "category": "新闻",
    "desc": "DO 是世界上第一个自带区块链经济体系、完全链上治理的央行算法稳定币协议，其发行完全基于智能合约、完全去中心化的，由美元和 ROC 双资产质押担保，与美元 1:1 锚定。",
    "date": "2021-04-07",
    "url": "https://www.chainnews.com/articles/296540221240.htm",
    "img": ""
  },
  {
    "title": "Mercurity.Finance 成功入选 Roxe Chain 超级节点",
    "category": "新闻",
    "desc": "近日，继 Dao Trust 成为 Roxe Chain 超级节点后，新加坡非营利组织 Roc Foundation Limited 宣布 Mercurity.Finance 也成功入选其超级节点，Roc Foundation Limited 是 Roxe Chain 的拥有和运营方。",
    "date": "2021-04-02",
    "url": "https://www.chainnews.com/articles/600861001327.htm",
    "img": ""
  },
  {
    "title": "ROC 上线 Uniswap 并开通 USDC-ROC 交易对",
    "category": "新闻",
    "desc": "\n4 月 1 日，Roxe 支付网络的生态代币 ROC 上线 Uniswap，并开通 USDC-ROC 交易对。",
    "date": "2021-04-01",
    "url": "https://www.chainnews.com/articles/718777205894.htm",
    "img": "https://img.chainnews.com/material/images/d05b56b10ebf180ad4294e46ac05e614.jpg-article"
  },
  {
    "title": "三分钟了解央行算法稳定币 DO",
    "category": "新闻",
    "desc": "DO 是世界上第一个自带区块链经济体系的、真正稳定的央行算法稳定币，目标是成为全球支付的基础组件，作为与各国法币自由、无摩擦、实时兑换和全球自由支付的万能货币，为用户实现一种货币在全球自由使用的便利。",
    "date": "2021-03-16",
    "url": "https://www.chainnews.com/articles/393237119585.htm",
    "img": ""
  },
  {
    "title": "Dao Trust 成功入选 Roxe Chain 超级节点",
    "category": "新闻",
    "desc": "近日，新加坡非营利组织 Roc Foundation Limited 宣布 Dao Trust 成功入选 Roxe Chain 超级节点。Roc Foundation Limited 是 Roxe Chain 的拥有和运营方。",
    "date": "2021-03-15",
    "url": "https://www.chainnews.com/articles/227402948117.htm",
    "img": "https://img.chainnews.com/material/images/c18b6db578a1199cc878b0779d423103.jpg-article"
  },
  {
    "title": "为什么 Roxe 比 Ripple XRPL 更适合全球支付？",
    "category": "新闻",
    "desc": "当下全球支付行业存在诸多问题：比如跨境汇款、商品和服务的支付速度慢、成本高、不可靠。与此同时，基于区块链技术的新型全球支付体系正在形成，而全球支付网络 Roxe 正以一种新的全球支付模式来解决上述问题。",
    "date": "2021-03-11",
    "url": "https://www.chainnews.com/articles/714136413139.htm",
    "img": "https://img.chainnews.com/material/images/8ead4d849da505ca4d77eca4183c9371.jpg-article"
  },
  {
    "title": "为什么 Ripple 的 XRP 无法作为桥梁资产",
    "category": "新闻",
    "desc": "CBDC 的主要目的是为了改善国内货币管理，加强对货币供应量的控制，减少对现金的依赖，同时加快境内各方的结算速度，增加自动化流程，减少物理流程等。",
    "date": "2021-03-12",
    "url": "https://www.chainnews.com/articles/742383439553.htm",
    "img": "https://img.chainnews.com/material/images/15190208738e81577ff5197568722eef.jpg-article"
  },
  {
    "title": "ROC 将于 3 月 10 日上线霍比特 HBTC",
    "category": "新闻",
    "desc": "3 月 10 日 19:00 （UTC+8），Roxe 支付网络的生态通证 ROC 将上线霍比特 HBTC，并开通 ROC/USDT 交易对。",
    "date": "2021-03-03",
    "url": "https://www.chainnews.com/articles/656707876986.htm",
    "img": "https://img.chainnews.com/material/images/9ef4ef36a67913532c05f886038d6b37.jpg-article"
  },
  {
    "title": "ROC 将于 3 月 10 日上线 BKEX Global",
    "category": "新闻",
    "desc": "3 月 10 日 19:00 （UTC+8），Roxe 支付网络的生态通证 ROC 将上线 BKEX Global，并开通 ROC/USDT 交易对。",
    "date": "2021-03-05",
    "url": "https://www.chainnews.com/articles/085610141469.htm",
    "img": "https://img.chainnews.com/material/images/19bb9d7924ca4519aaf982ab651504c4.jpg-article"
  },
  {
    "title": "Roxe 宣布推出基于区块链的全球 CBDC 支付网络",
    "category": "新闻",
    "desc": "中央银行数字货币 (CBDCs) 是今年的风向标，也可能是未来十年的风向标。",
    "date": "2021-03-09",
    "url": "https://www.chainnews.com/articles/049080984610.htm",
    "img": "https://img.chainnews.com/material/images/837a7753f2149e01ecf956b187f31577.jpg-article"
  },
  {
    "title": "资深科技金融界人士加持，Roxe 如何用区块链打造全球支付网络？",
    "category": "新闻",
    "desc": "Roxe 支付网络通过采用区块链技术，使个人或机构之间在 Roxe 网络上可以直接进行点对点的交易，即在几秒钟内完成交易。",
    "date": "2021-02-26",
    "url": "https://www.chainnews.com/articles/371494241105.htm",
    "img": "https://img.chainnews.com/material/images/c192122ade4443e2a5f60d42efeabc68.jpg-article"
  },
  {
    "title": "Roxe 聊天室 | 2021 影响加密货币行情的因素猜想",
    "category": "新闻",
    "desc": "Roxe 支付网络于 2 月 16 日在 Clubhouse 的【Roxe 聊天室】举行以“2021 影响加密货币行情的因素猜想”为主题的线上活动，吸引了业内资深投资者和从业者的关注，并引发了热烈探讨。",
    "date": "2021-02-22",
    "url": "https://www.chainnews.com/articles/376903085295.htm",
    "img": ""
  },
  {
    "title": "Roxe 权威解读 | 2021 算法稳定币的发展趋势",
    "category": "新闻",
    "desc": "Roxe 支付网络于 2 月 4 日，邀请了数字文艺复兴基金会董事总经理曹寅、TokenInsight 合伙人赵伟、红岸基金创始合伙人任骏菲、Roxe 支付网络-Daollar 协议核心开发 Shipu，围绕算法稳定币的发展趋势进行了深入探讨，Blocklike CEO 小琬担任研讨会主持人。",
    "date": "2021-02-19",
    "url": "https://www.chainnews.com/articles/796083923951.htm",
    "img": "https://img.chainnews.com/material/images/f7701f1f7748266438c50a5983c6ce0f.jpg-article"
  },
  {
    "title": "Roxe 如何帮助 Neobanks （新兴银行）实现更快速、更经济的国际支付？",
    "category": "新闻",
    "desc": "Roxe 轻结算网络（简称“Roxe”）能够使新兴银行和传统金融机构在数秒内（而不是数天）为客户提供跨境支付服务，同时减少 50% 以上的交易费用。",
    "date": "2021-02-20",
    "url": "https://www.chainnews.com/articles/025368300926.htm",
    "img": "https://img.chainnews.com/material/images/d026c686ee4cb912404026892feb8cff.jpg-article"
  },
  {
    "title": "Roxe 在线研讨会：区块链技术下国际支付的未来",
    "category": "新闻",
    "desc": "最近，Roxe 支付网络与多米尼加共和国中央银行系统与技术创新经理 Fabiola Herrera、快速发展的全球汇款公司 N2Xpress 首席执行官 Kunbi Oguneye、全球银行基础设施供应商 ECS Fin 首席执行官 Jacob Aruldhas，就如何使跨境支付更快速、更经济、更可靠进行了一场精彩的线上研讨会，Roxe 全球业务发展总监 Thomas Trepanier 担任会议主持人。",
    "date": "2021-02-06",
    "url": "https://www.chainnews.com/articles/333101800356.htm",
    "img": "https://img.chainnews.com/material/images/287a477bf10cecef1aeb22433e325b33.jpg-article"
  },
  {
    "title": "Roxe Chain ：全球支付新模型",
    "category": "新闻",
    "desc": "Roxe Chain 是专为全球支付和其他价值转移应用而打造的混合链，可连接高度分散的金融系统、资产和市场，使全球即时结算和跨境支付更快、更可靠、更具成本效益。",
    "date": "2021-01-15",
    "url": "https://www.chainnews.com/articles/177109275009.htm",
    "img": ""
  },
  {
    "title": "全球支付网络 Roxe 的模式被认可： 美国财政部允许银行使用区块链、稳定币",
    "category": "新闻",
    "desc": "美国时间 1 月 4 日，美国货币监理署 (OCC) 发布了一个重大消息：银行可以使用区块链和稳定币进行支付。美国货币监理署 (OCC) 的解释信不仅有助于加密支付成为主流，也进一步验证了下一代全球支付网络 Roxe 的可行性。",
    "date": "2021-01-12",
    "url": "https://www.chainnews.com/articles/653962847832.htm",
    "img": ""
  },
  {
    "title": "为什么Roxe在全球支付方面比瑞波（Ripple）更胜一筹？",
    "category": "新闻",
    "desc": "全球金融体系的高度分散，导致跨境支付太慢、太贵、太复杂，区块链技术能否解决以上痛点？",
    "date": "2021-01-18",
    "url": "https://news.huoxing24.com/20210118101806034002.html",
    "img": ""
  },
  {
    "title": "全球支付网络 Roxe 与加拿大 N2Xpress 联合推出区块链汇款服务",
    "category": "新闻",
    "desc": "12 月 17 日，下一代全球支付网络 Roxe 宣布与加拿大跨境汇款服务平台 N2Xpress 达成战略合作，N2Xpress 已成为其支付节点。双方将携手推动尼日利亚和印度地区汇款业务的发展，并在当地试行基于区块链的汇款服务。",
    "date": "2020-12-21",
    "url": "https://www.chainnews.com/articles/580840647452.htm",
    "img": "https://img.chainnews.com/material/images/7534c460cebecb3e8c2f13164ad9495d.jpg-article"
  },
  {
    "title": "Roxe研究：央行数字货币CBDC中采用区块链技术成为可能",
    "category": "新闻",
    "desc": "随着金融世界对7X24小时全天候国际清算的要求越来越高，市场需要更快、更好、更具成本效益的解决方案，区块链技术让整个国际清算过程更安全、更透明、更容易追踪。",
    "date": "2020-12-15",
    "url": "https://news.huoxing24.com/20201215171436678010.html",
    "img": ""
  },
  {
    "title": "\nRoxe与Onchain Custodian合作启动Roxe网络试点计划",
    "category": "新闻",
    "desc": "10月29日，Roxe宣布将与总部位于新加坡的Onchain Custodian达成合作，共同启动关于“测试Roxe轻结算网络”的试点计划。",
    "date": "2020-11-02",
    "url": "https://news.huoxing24.com/20201102143000133438.html",
    "img": ""
  },
  {
    "title": "Roxe Chain超级节点全球竞选计划已启动 助力全球资产价值转移",
    "category": "新闻",
    "desc": "Roxe Chain超级节点全球竞选计划已启动。",
    "date": "2020-09-15",
    "url": "https://news.huoxing24.com/20200915180242958888.html",
    "img": "https://hx24.huoxing24.com/image/news/2020/09/15/1600164186089455.jpg"
  },
  {
    "title": "Roxe项目进展（6月14日—6月20日）",
    "category": "项目周报",
    "desc": "Roxe运营周报，请查收！",
    "date": "2021-06-21",
    "url": "https://mp.weixin.qq.com/s/9P-D45e3j8vo2qEic3xBMQ",
    "img": ""
  },
  {
    "title": "Roxe项目进展（6月21日—6月27日）",
    "category": "项目周报",
    "desc": "Roxe运营周报，请查收！",
    "date": "2021-06-28",
    "url": "https://mp.weixin.qq.com/s/btvPtxjArYfjheSLK0z7FQ",
    "img": ""
  },
  {
    "title": "Roxe项目进展（6月28日—7月4日）",
    "category": "项目周报",
    "desc": "Roxe宣布正式推出Roxe支付协议和多种法币出入金解决方案。",
    "date": "2021-07-05",
    "url": "https://mp.weixin.qq.com/s/4XLeIbVG7mUqhYDsZ-C55w",
    "img": ""
  },
  {
    "title": "Roxe项目进展（7月5日—7月11日）",
    "category": "项目周报",
    "desc": "Roxe公布了最新发展路线图。",
    "date": "2021-07-12",
    "url": "https://mp.weixin.qq.com/s/5Aeqtu2_bxhay1F_Mu7OvQ",
    "img": ""
  },
  {
    "title": "Roxe项目进展（7月12日—7月18日）",
    "category": "项目周报",
    "desc": "重磅，速汇金前高管Ahmed Elhenawy加入Roxe支付网络。",
    "date": "2021-07-19",
    "url": "https://mp.weixin.qq.com/s/hukgQlnnJiqz0kO9egUpNQ",
    "img": ""
  },
  {
    "title": "Roxe项目进展（7月19日—7月25日）",
    "category": "项目周报",
    "desc": "Roxe在2021世界区块链大会上备受关注。",
    "date": "2021-07-26",
    "url": "https://mp.weixin.qq.com/s/4aP4Cqu_2ubPX_KZkpAvYw",
    "img": ""
  },
  {
    "title": "Roxe项目进展（7月26日—8月1日）",
    "category": "项目周报",
    "desc": "Roxe运营周报，请查收！",
    "date": "2021-08-02",
    "url": "https://mp.weixin.qq.com/s/MLUIgVI1hvm7r-X8FprQ_w",
    "img": ""
  },
  {
    "title": "Roxe项目进展（8月2日—8月8日）",
    "category": "项目周报",
    "desc": "Roxe全球支付网络进军巴西市场，与Rana Express合作推出汇款计划 。",
    "date": "2021-08-09",
    "url": "https://mp.weixin.qq.com/s/bgo3lD_sckCI5tBpdeMpBQ",
    "img": ""
  },
  {
    "title": "专访 | 区块链如何改进跨境汇款行业？",
    "category": "新闻",
    "desc": "Roxe首席商务官Josh Li在接受PYMNTS采访时表示，企业和个人可以通过将区块链技术与传统金融相结合的方式，进而加快和简化跨境汇款，同时避开传统结算基础设施和做法的挑战。",
    "date": "2021-07-01",
    "url": "https://new.qq.com/omn/20210701/20210701A069ZH00.html",
    "img": ""
  },
  {
    "title": "Roxe 宣布推出支付协议",
    "category": "新闻",
    "desc": "RPP是一个开放的、去中心化的、点对点的支付协议，旨在向个人、支付公司、银行和其他金融机构提供最佳的外汇汇率。",
    "date": "2021-07-02",
    "url": "https://www.163.com/dy/article/GDTNS8B505373CHU.html",
    "img": ""
  },
  {
    "title": "Roxe支付网络宣布为交易所推出多种法币出入金解决方案",
    "category": "新闻",
    "desc": "通过Roxe的多种法币出入金解决方案，数字资产交易所的企业和个人用户可以使用100多种法定货币，进行更快、更便宜的存款和取款。",
    "date": "2021-07-02",
    "url": "http://www.kejixun.com/article/210702/530662.shtml",
    "img": ""
  },
  {
    "title": "关于ROC应用场景升级的公告",
    "category": "新闻",
    "desc": "为了提升Roxe网络与传统金融系统的兼容性，进而扩大ROC的落地应用，Roxe对生态通证ROC的应用场景进行了升级。",
    "date": "2021-07-09",
    "url": "https://www.chainnews.com/articles/250143335618.htm",
    "img": ""
  },
  {
    "title": "金融科技如何通过区块链技术降低外汇成本？",
    "category": "新闻",
    "desc": "Roxe首席商务官Josh Li 表示：“当下的国际支付和汇款结算系统太慢、太贵、风险太大。银行和支付平台等中间机构太多导致汇款效率低下、成本高昂，也使得支付信息难以追踪。”",
    "date": "2021-07-09",
    "url": "https://news.huoxing24.com/20210709165650415090.html",
    "img": "https://hx24.huoxing24.com/image/news/2021/07/09/1625821028030420.png?x-oss-process=style/image_jpg"
  },
  {
    "title": "速汇金前高管Ahmed Elhenawy加入Roxe支付网络",
    "category": "新闻",
    "desc": "近日，全球支付网络Roxe宣布，已任命速汇金(MoneyGram)前高管Ahmed Elhenawy担任Roxe商务管理总监，负责中东地区的业务拓展。",
    "date": "2021-07-13",
    "url": "https://news.huoxing24.com/20210713162346849187.html",
    "img": "https://hx24.huoxing24.com/image/news/2021/07/13/1626164633981818.png"
  },
  {
    "title": "Roxe支付网络应邀出席Web3.0中国峰会",
    "category": "新闻",
    "desc": "7月17日，为期三天的Web3.0中国峰会暨IPFS区块链分布式存储行业生态大会（简称Web3.0中国峰会），在成都市高新区世纪城新国际会展中心圆满落幕。",
    "date": "2021-07-20",
    "url": "https://news.huoxing24.com/20210720184427556256.html",
    "img": "https://hx24.huoxing24.com/image/news/2021/07/20/1626777875537382.png?x-oss-process=style/image_jpg"
  },
  {
    "title": "Roxe最新发展路线图（中文版）",
    "category": "新闻",
    "desc": "请查收Roxe最新发展路线图。",
    "date": "2021-07-21",
    "url": "https://mp.weixin.qq.com/s/MyFHkZdQlpnV3z01DMsWOg",
    "img": ""
  },
  {
    "title": "Roxe支付网络受邀出席2021世界区块链大会",
    "category": "新闻",
    "desc": "2021年7月25日，以“无限未来”为主题的2021世界区块链大会在杭州圆满落幕，Roxe支付网络受邀出席本次盛会。",
    "date": "2021-07-27",
    "url": "https://www.chainnews.com/articles/595168048125.htm",
    "img": "https://img.chainnews.com/material/images/1fe3f1f0a4ba48a305591231ab64d956.jpg-article"
  },
  {
    "title": "Roxe项目进展（8月23日—8月29日）",
    "category": "项目周报",
    "desc": "近日，Roxe高管团队将迎来一位新成员。",
    "date": "2021-08-30",
    "url": "https://mp.weixin.qq.com/s/aWicaE3VPkl5nIlYx7CPXg",
    "img": ""
  },
  {
    "title": "Roxe项目进展（8月16日—8月22日）",
    "category": "项目周报",
    "desc": "Roxe运营周报，请查收！",
    "date": "2021-08-23",
    "url": "https://mp.weixin.qq.com/s/TzOBbHNsBdm1MqnGfKvRhA",
    "img": ""
  },
  {
    "title": "Roxe项目进展（8月9日—8月15日）",
    "category": "项目周报",
    "desc": "Venmo、MoneyGram、Bakkt、Uphold前高管加入Roxe，助力全球支付网络发展。",
    "date": "2021-08-16",
    "url": "https://mp.weixin.qq.com/s/ZQRI7VzMhDqG_vubDX5FBw",
    "img": ""
  },
  {
    "title": "Roxe：区块链赋能金融支付，全球价值实现互联互通",
    "category": "新闻",
    "desc": "Roxe可有效改变现有金融世界割裂、低效现状，且可兼容现存金融体系，让全球金融市场真正实现互联互通。",
    "date": "2021-08-26",
    "url": "https://www.toutiao.com/i7000625110885351975/",
    "img": ""
  },
  {
    "title": "Venmo、Bakkt、MoneyGram、Uphold的前高管加入Roxe全球支付网络",
    "category": "新闻",
    "desc": "近日，Roxe全球支付网络宣布，来自Venmo的前战略和运营主管Esther Sun Jang、Bakkt的数字资产托管系统前开发负责人Yamini Sagar、MoneyGram（速汇金）的前业务发展负责人Ahmed Elhenawy和全球加密货币交易和支付平台Uphold的前高级法律顾问Shujah Awan已经加入公司并担任领导职务。",
    "date": "2021-08-18",
    "url": "https://baijiahao.baidu.com/s?id=1708409767145366495&wfr=spider&for=pc",
    "img": ""
  },
  {
    "title": "Roxe全球支付网络进军巴西市场，与Rana Express合作推出汇款计划",
    "category": "新闻",
    "desc": "8月6日（美国时间），下一代全球支付网络Roxe宣布，国际汇款公司Rana Express将成为Roxe的节点，通过Rana钱包（Android和iOS版本）开展从美国到巴西的汇款业务。",
    "date": "2021-08-12",
    "url": "https://baijiahao.baidu.com/s?id=1707854728817319043&wfr=spider&for=pc",
    "img": ""
  },
  {
    "title": "攻击、挑衅、归还...Poly Network黑客的行为艺术或将加速监管落地",
    "category": "新闻",
    "desc": "Roxe支付网络技术VP Jesse表示，中心化和去中心化都各有所长、缩短，用户既希望100%的独立控制资产，又希望一旦出现问题有人能帮忙找回。",
    "date": "2021-08-11",
    "url": "https://www.jinse.com/blockchain/1142128.html",
    "img": ""
  },
  {
    "title": "Roxe项目进展（8月30日—9月5日）",
    "category": "项目周报",
    "desc": "Roxe将参加2021年Money20/20拉斯维加斯大会。",
    "date": "2021-09-08",
    "url": "https://mp.weixin.qq.com/s/W7NBDpW-S_Wij3MB-PiSow",
    "img": ""
  },
  {
    "title": "Roxe持续拓展巴西市场，与Treviso合作推出汇款计划",
    "category": "新闻",
    "desc": "下一代全球支付网络Roxe于近日宣布，外汇金融机构Treviso将成为Roxe的节点，开展从美国到巴西的汇款业务，持续拓展巴西市场。",
    "date": "2021-09-08",
    "url": "https://news.huoxing24.com/20210908160114065673.html",
    "img": "https://hx24.huoxing24.com/image/news/2021/09/08/1631088080118126.png?x-oss-process=style/image_jpg"
  },
  {
    "title": "前国际货币基金组织（IMF）高级经济学家Andy Jobst加入Roxe支付网络",
    "category": "新闻",
    "desc": "Roxe全球支付网络宣布，已任命前国际货币基金组织（IMF）高级经济学家Andy Jobst担任Roxe首席货币经济学家，负责比特币算力支持的CBDC项目（又称CBDC+）。",
    "date": "2021-09-09",
    "url": "https://news.huoxing24.com/20210909120327339943.html",
    "img": ""
  },
  {
    "title": "Roxe APP先行版正式上线，限量开放白名单",
    "category": "新闻",
    "desc": "Roxe APP是基于Roxe生态打造的一款多资产智能移动支付应用，具有购物返现，好友转账、点赞、评论、借贷以及余额投资等功能。",
    "date": "2021-09-10",
    "url": "https://news.huoxing24.com/20210910120509969301.html",
    "img": "https://hx24.huoxing24.com/image/crawler/2021/09/10/1631246709677489.jpg"
  },
  {
    "title": "Roxe项目进展（9月6日—9月12日）",
    "category": "项目周报",
    "desc": "ROC将于近日跨链至币安智能链BSC，进军BSC生态。",
    "date": "2021-09-16",
    "url": "https://mp.weixin.qq.com/s/QDTtJoGvFAs9DoFYlfDk1g",
    "img": ""
  },
  {
    "title": "Roxe生态通证ROC将跨链至币安智能链（BSC）",
    "category": "新闻",
    "desc": "Roxe全球支付网络宣布，其生态通证ROC将从以太坊跨链至币安智能链（BSC），通过BSC跨链桥可把以太坊和币安智能链上的ROC进行相互转移。",
    "date": "2021-09-15",
    "url": "https://news.huoxing24.com/20210915155249661062.html",
    "img": "https://hx24.huoxing24.com/image/news/2021/09/15/1631692374547668.png"
  },
  {
    "title": "Roxe项目进展（9月13日-9月19日）",
    "category": "项目周报",
    "desc": "ROC跨链空投火热进行中，截止到跨链桥上线",
    "date": "2021-09-23",
    "url": "https://mp.weixin.qq.com/s/nNSOjQorWZfRzh4Wy8Mxiw",
    "img": ""
  },
  {
    "title": "Roxe项目进展（9月20日—9月26日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2021-09-29",
    "url": "https://mp.weixin.qq.com/s/tMdXgYNzLatSh9NndqMaFg",
    "img": ""
  },
  {
    "title": "Roxe项目进展（9月27日—10月10日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2021-10-14",
    "url": "https://mp.weixin.qq.com/s/JJ9zP3cLlkOdpQWtW1gJsQ",
    "img": ""
  },
  {
    "title": "Roxe项目进展（10月11日—10月17日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2021-10-19",
    "url": "https://mp.weixin.qq.com/s/JjA_7CMb6UB40O4YR5XvTg",
    "img": ""
  },
  {
    "title": "ROC将于10月8日上线PancakeSwap",
    "category": "新闻",
    "desc": "10月8日15:00（UTC+8），Roxe全球支付网络（简称Roxe）生态通证ROC将上线去中心化交易所（DEX）PancakeSwap，并同步开启ROC-BUSD交易对。",
    "date": "2021-09-28",
    "url": "https://new.qq.com/omn/20210928/20210928A0BNJT00.html",
    "img": ""
  },
  {
    "title": "Roxe Chain将于10月8日推出ROC跨链桥 布局BSC生态",
    "category": "新闻",
    "desc": "Roxe全球支付网络（简称Roxe）宣布，将于10月8日17:00（UTC+8）开放ROC的“以太坊⇌BSC跨链桥”合约地址。",
    "date": "2021-09-29",
    "url": "https://www.jinse.com/news/blockchain/1166144.html",
    "img": "https://img.jinse.com/5650632_image3.png"
  },
  {
    "title": "Roxe项目进展（10月18日—10月24日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2021-10-26",
    "url": "https://mp.weixin.qq.com/s/3UB7fwj99aTXUGgn8mWFfA",
    "img": ""
  },
  {
    "title": "Roxe项目进展（10月25日—10月31日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2021-11-02",
    "url": "https://mp.weixin.qq.com/s/zmJYfWOEZtpKXtPqh9brhQ",
    "img": ""
  },
  {
    "title":"Roxe项目进展（11月01日—11月07日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2021-11-09",
    "url": "https://mp.weixin.qq.com/s/OKK9gQEVvncIwBSloefawQ",
    "img": ""
  },
  {
    "title":"Roxe项目进展（11月08日—11月14日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2021-11-16",
    "url": "https://mp.weixin.qq.com/s/15tbh9oCswGDXbCrj2ufZg",
    "img": ""
  },
  {
    "title":"Roxe项目进展（11月15日—11月21日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2021-11-23",
    "url": "https://mp.weixin.qq.com/s/a5Gl53jM_HzN1EO7QwaRNA",
    "img": ""
  },
  {
    "title":"Roxe项目进展（11月22日—11月28日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2021-11-28",
    "url": "https://mp.weixin.qq.com/s/5Yfo-CXc4gFED_qwcSB_Sw",
    "img": ""
  },
  {
    "title":"Roxe项目进展（11月29日—12月05日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2021-12-05",
    "url": "https://mp.weixin.qq.com/s/ifS6NnxHzp67McT8-OhggQ",
    "img": ""
  },
  {
    "title": "Roxe项目进展（12月06日—12月12日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2021-12-12",
    "url": "https://mp.weixin.qq.com/s/mIDdT09vi3jUbMmCJRDSKA",
    "img": ""
  },
  {
    "title": "Roxe项目进展（12月13日—12月19日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2021-12-19",
    "url": "https://mp.weixin.qq.com/s/8ow2IFhtIpOJXcgSAFWC7A",
    "img": ""
  },
  {
    "title": "Roxe项目进展（12月20日—12月26日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2021-12-26",
    "url": "https://mp.weixin.qq.com/s/dvxaK7KDTj4APwBKdwjfWg",
    "img": ""
  },
  {
    "title": "Roxe项目进展（12月27日—1月2日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2022-01-02",
    "url": "https://mp.weixin.qq.com/s/TdjXAQhh3jsAecyKjuLjJg",
    "img": ""
  },
  {
    "title": "Roxe项目进展（1月3日—1月9日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2022-01-09",
    "url": "https://mp.weixin.qq.com/s/lIONd_lGJsBccS4UhA7YEg",
    "img": ""
  },
  {
    "title": "Roxe项目进展（1月10日—1月16日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2022-01-16",
    "url": "https://mp.weixin.qq.com/s/jOqsSfVdswyqgpnA-DHm3w",
    "img": ""
  },
  {
    "title": "Roxe项目进展（1月17日—1月23日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2022-01-23",
    "url": "https://mp.weixin.qq.com/s/Dv6pvDdKP0Yn08QOwCW_pA",
    "img": ""
  },
  {
    "title": "Roxe项目进展（1月24日—1月30日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2022-01-30",
    "url": "https://mp.weixin.qq.com/s/nDuWuizE8E46gFahcmnIjw",
    "img": ""
  },
  {
    "title": "Roxe项目进展（1月31日—2月6日）",
    "category": "项目周报",
    "desc": "欢迎关注Roxe Chain。",
    "date": "2022-02-06",
    "url": "https://mp.weixin.qq.com/s/NmxiCKtdFU3CBgkL1buTAA",
    "img": ""
  }
]