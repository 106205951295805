<template>
  <div id="about">
    <div class="h-banner">
      <div class="h-title">
        Roxe Blog
      </div>
    </div>
    <div class="blogs">
      <div class="list">
        <div v-bind:key="item" v-for="(item,idx) in tabs" @click="tab(idx)" :class="['item',index==idx?'active':'']">{{item}}</div>
      </div>
      <div class="grid">
        <Item v-bind:key="index" v-for="(data,index) in items" :data="data"/>
      </div>
    </div>
  </div>
</template>

<script>
// import RoxeFooter from "../../components/footer.vue";
import * as en from './data';
import * as cn from './zh-cn';
import Item from './item.vue';
export default {
  name: "about",
  components: {
    Item
  },
  data() {
    return {
      index: 0,
    }
  },
  computed:{
    tabs() {  
      if(this.$i18n.locale == 'zh-cn') {
        return cn.category;
      } else {
        return en.category;
      }
    },
    items() {
      let data = en;
      if(this.$i18n.locale == 'zh-cn') {
        data  = cn;
      }
      if(this.index == 0) {
        return data.items.sort((a,b)=>{
          return a.date <= b.date?1:-11;
        });
      } else {
        return data.items.filter((it)=>{
          return it.category.startsWith(this.tabs[this.index]);
        }).sort((a,b)=>{
          return a.date <= b.date?1:-1;
        });
      } 
    }
  },
  methods: {
    tab(idx) {
      this.index = idx;
    }
  },
};
</script>
<style lang="less" scoped> 
#about {
  .h-banner {
    height: 386px;
    background: linear-gradient(180deg, #085da3 0%, #3a89ca 100%);
    overflow: hidden;
    color: #ffffff;
    position: relative;
    .h-title {
      font-family: ParmaPetit;
      font-size: 80px;
      line-height: 100.3%;
      text-align: center;
      margin: 160px 0px 20px;
    }
    .h-title1 {
      font-family: Louis George Cafe;
      font-size: 26px;
      line-height: 20px;
      text-align: center;
    }
  }
  .blogs{
    font-family: Louis George Cafe;
    background: #E5E5E5;
    padding-bottom: 100px;
    .list{
      justify-content: center;
      align-items: center;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.04);
      border-radius: 6px;
      background: #fff;
      top: -30px;
      position: relative;
      width: 550px;
      margin: 0 auto;
      height: 60px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 50px;
      font-size: 12px;
      .item{
        height: 22px;
        line-height: 22px;
        cursor: pointer;
        border-radius: 4px;
        padding: 0 10px 0 10px;
        margin-left: 10px;
        color: #828282;
        &:hover{
          background: #2F98EE;
          color: #fff;
        }
        &.active {
          background: #2F98EE;
          color: #fff;
        }
      }
    }
    .grid {
      display: grid;
      width: 80%;
      max-width: 1280px;
      margin:0 auto;
      grid-template-columns:repeat(auto-fill,minmax(350px,1fr));
    }
  }
  
  .p2 {
    width: 1110px;
    margin: 0 auto;
    padding: 84px 0px 76px 0px;
    display: flex;
    justify-content: space-between;
    img {
      width: 636px;
      // height: 517px;
      display: block;
    }
    .introduce {
      width: 439px;
      // margin-top: 63px;
      .title {
        font-family: ParmaPetit;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 100.3%;
        color: #085DA3;
        margin-bottom: 20px;
      }
      .text {
        font-family: Louis George Cafe;
        font-size: 16px;
        line-height: 19px;
        color: #818E9E;
        margin-bottom: 20px;
      }
    }
  }

  .p3 {
    background: #F6F8FB;
    padding-bottom: 60px;
    .p3-box {
      width: 1110px;
      margin: 0 auto;
      .title {
        text-align: center;
        font-family: ParmaPetit;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 35px;
        color: #085DA3;
        padding-top: 45px;
        margin-bottom: 54px;
      }
      .list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item {
          max-width: 255px;
          height: 228px;
          padding: 30px 28px 44px;
          background: #FFFFFF;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
          img {
            width: 50px;
            height: 50px;
            margin: 0 auto;
            margin-bottom: 26px;
            display: block;
          }
          .text {
            font-family: Louis George Cafe;
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            color: #085DA3;
          }
        }
      }
    }
  }

  .p7 {
    .title {
      font-family: ParmaPetit;
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 35px;
      text-align: center;
      color: #085DA3;
      margin-top: 74px;   
      margin-bottom: 53px;     
    }
    .link {
      display: flex;
      justify-content: center;
      margin-bottom: 110px;
      a {
        margin-right: 30px;
        img {
          display: inline-block;
          width: 30px;
        }
        &:hover {
          img {
            filter: contrast(0.5);
          }
        }
      }
    }
    
  }
}
@media screen and (min-width: 320px) and (max-width: 1210px) {
  #about .p2 {
    padding-left: 50px;
    padding-right: 50px;
    flex-wrap: wrap;
    width: auto;
    justify-content: center;
    .introduce {
      width: auto;
    }
    img {
      width: 100%;
      display: block;
      height: auto;
      margin-bottom: 30px;
      // height: auto;
    }
  }
  #about .p3 {
    .p3-box {
      padding-left: 50px;
      padding-right: 50px;
      width: auto;
      .list {
        .item {
          width: 100%;
          max-width: none;
          height: auto;
          margin-bottom: 30px;
        }
      }
    }
  }
  
}

</style>
